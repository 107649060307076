const isDynamicImportError = (error: Error) => {
  const errorRepresentation = `${error.name}: ${error.message}`;

  return (
    /.*typeerror.*module.*/gi.test(errorRepresentation) ||
    /.*text\/html.*mime.*type.*/gi.test(errorRepresentation) ||
    /.*Unexpected token '<'.*/gi.test(errorRepresentation) ||
    /.*expected expression, got '<'.*/gi.test(errorRepresentation) ||
    /.*Failed to fetch dynamically imported module.*/gi.test(errorRepresentation) ||
    /.*Unable to preload CSS.*/gi.test(errorRepresentation)
  );
};

export default isDynamicImportError;
